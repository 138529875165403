import React from "react";
import { ThemeProvider } from "@mui/material";
import { styled } from "@mui/styles";
import { Header, Homepage, Footer } from "./components";
import "./styles/App.css";
import theme from "./styles/theme";

const Container = styled("div")(({ theme }) => ({
  display: "flex",
  width: "100vw",
  height: "100vh",
  justifyContent: "start",
  flexDirection: "column",
  boxSizing: "border-box",
  backgroundColor: theme.palette.background,
}));

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Header />
        <Homepage />
        <Footer />
      </Container>
    </ThemeProvider>
  );
};

export default App;
