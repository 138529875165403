import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/styles';
const NRG_AMOUNT = process.env.REACT_APP_NRG_AMOUNT_TO_DISPERSE

const Container = styled('div')(({ theme }) => ({
  bottom: '0px',
  left: '0px',
  display: 'flex',
  width: '100%',
  height: '20%',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  gap: '30px',
  backgroundColor: theme.palette.background,
  boxSizing: 'border-box',
  padding: '20px'
}));

const Footer = () => {
  return (
    <Container>
      <Typography variant='h5'>How does this work?</Typography>
      <Typography>
        The faucet is running on the Energi test network. It allows users to
        request <strong>{NRG_AMOUNT} tNRG</strong> coins every 8 hours.
      </Typography>
    </Container>
  );
};

export default Footer;
