const axios = require('axios');
const SERVER_URL = process.env.REACT_APP_SERVER_URL || '';

export const sendRequest = async (address) => {
try {
  const res = await axios.post(SERVER_URL, {address: address});
  return res;
} catch (e) {
  console.error(e);
  return e;
  };
};
