import React from 'react';
import { Typography } from '@mui/material';
import { styled } from '@mui/styles';
import { ReactComponent as EnergiLogo } from '../assets/energilogo.svg';

const Container = styled('div')(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1rem',
  boxSizing: 'border-box',
  gap: '15px',
  backgroundColor: theme.palette.background,
  boxShadow: `0px 0px 15px ${theme.palette.background}`,
}));

export default function Header() {
  return (
    <Container>
      <EnergiLogo style={{ width: '40', height: '40' }} />
      <Typography variant='h4' fontWeight={100} color={'#B239EB'}>
        ENERGI TESTNET FAUCET
      </Typography>
    </Container>
  );
};
