import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Typography,
  Link,
} from '@mui/material';
import { Done, Close } from '@mui/icons-material';
import { styled } from '@mui/styles';
import { sendRequest } from '../utils';
import { ENERGI_TESTNET_EXPLORER } from '../constants';

const Container = styled('div')(() => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StyledBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
}));

const AddressInput = styled(TextField)(({theme}) => ({
  width: '500px',
  borderRadius: '30px',
  borderColor: theme.palette.white,

  '& .MuiOutlinedInput-root': {
    borderRadius: '30px',
    fontSize: '16px',
  },
}));

const LoaderContainer = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingTop: '85px',
}));

const StyledCircularProgress = styled(CircularProgress)(() => ({
  border: `1px solid #a6a6a6`,
  padding: '10px',
  borderRadius: '50%',
}));

const CircleIcon = styled('div')(({ success = 0 }) => ({
  height: '100px',
  width: '100px',
  border: `1px solid ${success ? '#25E47A' : '#FF4D4D'}`,
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: 'rgba(100, 100, 111, 0.3) 0px 0px 10px 1px',

  '& svg': {
    height: '90px',
    width: '90px',
    fill: success ? '#25E47A' : '#FF4D4D',
  },
}));

const ResponseDiv = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '25px',
  flexDirection: 'column',
}));

const StyledButton = styled(Button)(() => ({
  height: '52px',
  width: '140px',
  borderRadius: '36px !important',
}));

const validateInput = (address = '') => {
  return /^(0x){1}[0-9a-fA-F]{40}$/i.test(address);
};

const Homepage = () => {
  const [inputAddress, setInputAddress] = useState('');
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [response, setResponse] = useState('');

  const [resMessage, setResMessage] = useState(
    `Your transaction has been sent. You can verify the transaction at  ${(
      <a href={ENERGI_TESTNET_EXPLORER + 'asas'}>transaction.</a>
    )}`
  );

  const handleChange = (event) => {
    if (validateInput(event?.target?.value) || event?.target?.value === '') {
      setInputAddress(event?.target?.value);
      setIsAddressValid(false);
    } else {
      setIsAddressValid(true);
    };
  };

  const handleClick = async () => {
    if (!validateInput(inputAddress)) {
      setIsAddressValid(false);
      return '';
    };
    setResponse('loading');
    const response = await sendRequest(inputAddress);
    if (response?.status === 201) {
      setResMessage(response?.data?.transactionHash);
      setResponse('success');
    } else if (response?.status === 200) {
      setResMessage(response?.data?.message);
      setResponse('failed');
    } else {
      setResMessage(`The service is down, please try again later.`);
      setResponse('failed');
    };
  };

  const handleGoBack = () => {
    setResMessage('');
    setResponse('');
  }

  return (
    <Container>
      {response === '' && (
        <StyledBox component='form' noValidate autoComplete='off'>
          <AddressInput
            error={isAddressValid}
            helperText={isAddressValid ? 'Please enter a valid address.' : ''}
            id='outlined-basic'
            placeholder={'Enter your address...'}
            variant='outlined'
            type='text'
            onChange={handleChange}
          />
          <StyledButton
            disabled={isAddressValid}
            variant='outlined'
            onClick={handleClick}
          >
            Submit
          </StyledButton>
        </StyledBox>
      )}

      {response === 'loading' && (
        <LoaderContainer>
          <StyledCircularProgress size={100} />
        </LoaderContainer>
      )}

      {response === 'success' && (
        <ResponseDiv>
          <CircleIcon success={1}>
            <Done />
          </CircleIcon>
          <Typography>
            Congratulations! Your testnet NRG has been sent. You can verify the transaction {' '}
            <Link href={ENERGI_TESTNET_EXPLORER + resMessage}>here.</Link>
          </Typography>
        </ResponseDiv>
      )}
      {response === 'failed' && (
        <ResponseDiv>
          <CircleIcon success={0}>
            <Close />
          </CircleIcon>
          <Typography>{resMessage}</Typography>
          <StyledButton
            disabled={isAddressValid}
            variant='outlined'
            onClick={() => handleGoBack()}
          >
            Go Back
          </StyledButton>
        </ResponseDiv>
      )}
    </Container>
  );
};

export default Homepage;
