
import { createTheme } from '@mui/material';

const theme = createTheme({
  typography: {
    allVariants: {
      color: "white"
    },
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#B239EB' 
    },
    white: '#FFFFFF',
    background: '#000000',
    backgroundLight: '#212329'
  }
});

export default theme;
